import React from 'react';
import styles from './form.module.scss';

export default function TextArea({ name, value, required, handleChange }) {
  return(
    <textarea
      className={`${styles.textarea} ${styles.formControl}`}
      name={name}
      rows={6}
      required={required}
      value={value}
      onChange={(event) =>handleChange(event.target.value)}
    />
  );
}
